import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`-Skill Practice: Wall Walks`}</p>
    <p>{`-8 rounds of tabata (:20 on :10 off) Rowing for total calories`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Rankor”`}</strong>{` `}<em parentName="p">{` (made by Brian Bennett circa 2009)`}</em></p>
    <p>{`25 Wall Walks`}</p>
    <p>{`50 Box Jumps (24/20″)`}</p>
    <p>{`75 KBS’s (53/35`}{`#`}{`)`}</p>
    <p>{`*`}{`emom: 4 burpees`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`Our annual Free Thanksgiving Day workout will be at 9:00am on
Thursday.  Partner WOD so bring a friend!  The gym will only be open for
this class.`}</strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`*`}{`There will be no open gym on Friday but all open gym members are
welcome to come to our classes at no charge.  We will cancel the 6am &
6pm classes on Friday but the 10:30, 12:00, 4:00 & 5:00 classes are
available.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      